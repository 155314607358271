import { useSelector, useDispatch } from 'react-redux';

function DrumMachine() {
    return (
        <div id="drum-machine">
            <Keys />
            <Options />
        </div>
    )
}

const keyLetters = ['Q', 'W', 'E', 'A', 'S', 'D', 'Z', 'X', 'C'];

function Keys() {
    const dispatch = useDispatch();
    const volumeState = useSelector((state) => state.volume);
    document.addEventListener("keydown", (event) => {
        const audioElement = document.getElementById(event.key.toUpperCase());
        if (audioElement != null) {
            dispatch({ type: 'DISPLAY_CHANGE', display: `SOUND ${audioElement.id}` })
            audioElement.load();
            audioElement.volume = volumeState / 100;
            let playProcess = audioElement.play();
            if (playProcess !== undefined) {
                playProcess.then(_ => {
                    //Play the audio clip
                }).catch(error => {
                    //Get async exception
                });
            }
        }
    });
    return (
        <div id="keys">
            {keyLetters.map((l) => { return <Key letter={l} /> })}
        </div>
    )
}

function Key({ letter }) {
    const bankState = useSelector((state) => state.bank);
    const powerState = useSelector((state) => state.power);
    const volumeState = useSelector((state) => state.volume);
    const dispatch = useDispatch();
    const playFunction = () => {
        const audioElement = document.getElementById(letter);
        dispatch({ type: 'DISPLAY_CHANGE', display: `SOUND ${audioElement.id}` })
        audioElement.load();
        audioElement.volume = volumeState / 100;
        let playProcess = audioElement.play();
        if (playProcess !== undefined) {
            playProcess.then(_ => {
                //Play the audio clip
            }).catch(error => {
                //Get async exception
            });
        }
    }
    return (
        <button
            id={letter + "_key"}
            className="drum-pad"
            disabled={!powerState}
            onClick={playFunction}
        >
            {letter}
            <audio className='clip' src={`/sounds/${+ bankState}/${letter}.mp3`} id={letter}></audio>
        </button>
    )
}

function Options() {
    return (
        <div id="options">
            <Power />
            <Display />
            <Volume />
            <Bank />
        </div>
    );
}

function Power() {
    const powerState = useSelector((state) => state.power);
    const dispatch = useDispatch();
    return (
        <div className="slider_container">
            <label htmlFor="power">Power</label>
            <input type="range" name="power" id="power" min="0" max="1" step="1"
                defaultValue={+ powerState}
                onChange={e => dispatch({ type: 'POWER_CHANGE' })}
            />
        </div>
    );
}

function Bank() {
    const powerState = useSelector((state) => state.power);
    const bankState = useSelector((state) => state.bank);
    const dispatch = useDispatch();
    return (
        <div className="slider_container">
            <label htmlFor="bank">Bank</label>
            <input type="range" name="bank" id="bank" min="0" max="1" step="1"
                defaultValue={+ bankState}
                onChange={e => dispatch({ type: 'BANK_CHANGE' })}
                disabled={!powerState}
            />
        </div>
    );
}

function Volume() {
    const powerState = useSelector((state) => state.power);
    const volumeState = useSelector((state) => state.volume);
    const dispatch = useDispatch();
    return (
        <div id="volume_container">
            <p>Volume</p>
            <input type="range" name="volume" id="volume" min="0" max="100" step="1"
                defaultValue={volumeState}
                onChange={e => dispatch({ type: 'VOLUME_CHANGE', volume: e.target.value })}
                disabled={!powerState}
            />
        </div>
    );
}

function Display() {
    const displayText = useSelector((state) => state.display);
    return (
        <p id="display">{displayText}</p>
    )
}

export default DrumMachine;
