const initState = {
    power: true,
    bank: false,
    volume: 50,
    display: 'Power ON'
};

const soundBank = ['Drums', 'Guitar'];
const powerTexts = ['OFF', 'ON'];

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case 'POWER_CHANGE':
            return {
                ...state,
                power: !state.power,
                display: `Power ${powerTexts[+ !state.power]}`
            };
        case 'BANK_CHANGE':
            return {
                ...state,
                bank: !state.bank,
                display: `${soundBank[+ !state.bank]} Kit`
            };
        case 'VOLUME_CHANGE':
            return {
                ...state,
                volume: action.volume,
                display: `Volume ${action.volume}`
            };
        case 'DISPLAY_CHANGE':
            return {
                ...state,
                display: action.display
            }
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}
